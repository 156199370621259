import { decode } from 'html-entities';
import type {
  IWorkflowRule,
  INewWorkflowRule,
  NewRuleType,
  WorkflowRuleMap,
  EditableWorkflowRules,
} from 'types/IWorkflows';
import type {
  IFleetDetail,
  IVehicleSelection,
  VehicleSelectionMap,
} from 'types/IFleetDetails';

const SAVING_STAGE = {
  CLOSED: 'CLOSED',
  ASSOCIATE_VEHICLES: 'ASSOCIATE_VEHICLES',
  NAMING: 'NAMING',
};

/**
 *
 * @param id
 * @returns
 */
const isAF1800Rule = (id: number | string) => (typeof id === 'string' && id.startsWith('AF1800'));

/**
 *
 * @param type
 * @param ruleId
 * @returns
 */
const isCounterField = (type: string | undefined, ruleId: string | number) => (
  (type === 'counter') && !['AF1800-H', 'AF1800-M'].includes(String(ruleId))
);

/**
 *
 * @param newRuleType
 * @param id
 * @returns
 */
const newRuleGenerator = (
  newRuleType: NewRuleType | undefined,
  ruleId: string,
  availableRules: IWorkflowRule[] | undefined,
): INewWorkflowRule | undefined => {
  if (newRuleType === 'PASS_FAIL') {
    return {
      ruleId,
      newRuleType,
      ruleType: 'passFail',
    };
  }

  if (newRuleType === 'NUMERIC_INPUT') {
    return {
      ruleId,
      newRuleType,
      ruleType: 'counter',
    };
  }

  if (newRuleType === 'CUSTOM' || newRuleType === 'MISSING_AF_1800') {
    return {
      ruleId,
      newRuleType,
      availableRules,
    };
  }

  return undefined;
};

/**
 *
 * @param key
 * @returns
 */
const sortVehiclesBy = (key: keyof IVehicleSelection) => (
  (a: IVehicleSelection, b:IVehicleSelection) => (
    ((a[key] || '') > (b[key] || '')) ? 1 : (((b[key] || '') > (a[key] || '')) ? -1 : 0)
  )
);

/**
 *
 * @param vehicles
 * @param fieldKey
 * @returns
 */
const groupVehicleBy = (vehicles: IVehicleSelection[], fieldKey: keyof IVehicleSelection) => (
  vehicles.reduce((mappedRows: Record<string, IVehicleSelection[]>, row) => {
    const mapKey = row[fieldKey] as string;
    return {
      ...mappedRows,
      [mapKey]: [...(mappedRows[mapKey] || []), row].sort(sortVehiclesBy(fieldKey)),
    };
  }, {})
);

const groupByVehicleType = (vehicles: IVehicleSelection[]) => groupVehicleBy(vehicles, 'vehicleType');

const groupByWorkflow = (vehicles: IVehicleSelection[]) => groupVehicleBy(vehicles, 'workflowId');

/**
 *
 * @param rules
 * @returns
 */
const keyById = (rules: IWorkflowRule[]) => rules.reduce((ruleMap:WorkflowRuleMap, rule) => ({
  ...ruleMap,
  [rule.ruleId]: rule,
}), {});

/**
 *
 * @param vehicles
 * @returns
 */
const keyByRegNumber = (vehicles: IFleetDetail[]) => vehicles.reduce((regNumberMap, vehicle) => ({
  ...regNumberMap,
  [vehicle.regNumber]: vehicle,
}), {});

const keyByRegNumberWithSelected = (vehicles: IFleetDetail[], workflow?: string) => (
  vehicles.reduce((regNumberMap: VehicleSelectionMap, vehicle) => ({
    ...regNumberMap,
    [vehicle.regNumber]: {
      ...vehicle,
      selected: vehicle.workflowTitle === workflow,
    },
  }), {}));

const isRuleValid = (currentRule: EditableWorkflowRules) => (
  currentRule.isRemoved
  || (
    currentRule.title
    && currentRule.frequency
    && (
      currentRule.ruleType === 'passFail'
      || currentRule.ruleType === 'counter'
      || (
        currentRule.ruleType === 'threshold'
        && currentRule.fields?.threshold
        && currentRule.fields?.threshold >= currentRule.fields?.absMin
        && currentRule.fields?.threshold <= currentRule.fields?.absMax
      )
      || (
        currentRule.ruleType === 'lookup'
        && Number.isFinite(currentRule.fields?.target)
        && currentRule.fields?.target > 0
      )
    )
  )
);

const isRuleModified = (
  currentRule: EditableWorkflowRules,
  originalRule: IWorkflowRule | undefined,
) => (
  decode(currentRule.title) !== decode(originalRule?.title)
  || decode(currentRule.description) !== decode(originalRule?.description)
  || currentRule.frequency !== originalRule?.frequency
  || (
    currentRule.ruleType === 'threshold'
    && currentRule.fields?.threshold !== originalRule?.fields?.threshold
  )
  || (
    currentRule.ruleType === 'lookup'
    && currentRule.fields?.target !== originalRule?.fields?.target
  )
);

const isWorkflowModifiedAndValid = (
  workflowRules: EditableWorkflowRules[],
  originalRulesMap: WorkflowRuleMap,
) => (
  workflowRules.every((rule) => isRuleValid(rule))
  && !workflowRules.every((rule) => rule.isRemoved)
  && workflowRules.some((rule) => (
    rule.isRemoved
     || (
       originalRulesMap
      && isRuleModified(rule, originalRulesMap[rule.originalRuleId || rule.ruleId])
     )
  ))
);

export {
  groupByVehicleType,
  groupByWorkflow,
  isAF1800Rule,
  isCounterField,
  isRuleModified,
  isRuleValid,
  isWorkflowModifiedAndValid,
  keyById,
  keyByRegNumber,
  keyByRegNumberWithSelected,
  newRuleGenerator,
  SAVING_STAGE,
};
