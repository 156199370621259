import React from 'react';
import {
  Button,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { decode } from 'html-entities';
import { MdDelete } from 'react-icons/md';
import { useAtom, type PrimitiveAtom } from 'jotai';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import type { EditableWorkflowRules, IWorkflowRule, INewWorkflowRule } from 'types/IWorkflows';
import type { RuleFrequency } from 'types/common';

import {
  WORKFLOW_RULE_LABEL,
  WORKFLOW_NEW_RULE_LABEL,
  WORKFLOW_ADDED_EXISTING_RULES,
  WORKFLOW_CREATED_RULES,
} from 'utils/Constants';

import AF1800Badge from './ruleComponents/Af1800Badge';
import DescriptionField from './ruleComponents/DescriptionField';
import RemovedStateOverlay from './ruleComponents/RemovedStateOverlay';
import FrequencyField from './ruleComponents/FrequencyField';
import NumericInputTargetField from './ruleComponents/NumericInputTargetField';
import ThresholdTargetField from './ruleComponents/ThresholdTargetField';
import TitleField from './ruleComponents/TitleField';

import {
  isAF1800Rule,
  isCounterField,
  isRuleModified,
  isRuleValid,
} from './workflowUtils';

interface ISortableWorkflowRule {
  ruleId: number | string,
  rulesAtom: PrimitiveAtom<EditableWorkflowRules[]>,
  originalRule: IWorkflowRule | undefined,
}

interface INewPredefinedRule {
  rulesAtom: PrimitiveAtom<EditableWorkflowRules[]>,
  newRule: INewWorkflowRule,
}

function NewPredefinedRule({ newRule, rulesAtom } : INewPredefinedRule) {
  const [rules, setRules] = useAtom(rulesAtom);
  const { newRuleType, availableRules } = newRule;

  if (!newRuleType || !availableRules) {
    return <div />;
  }

  const onSelection = (selectedId: string) => {
    const foundRule = availableRules.find((x) => (
      x.ruleId === selectedId
    ));

    if (!foundRule) {
      return;
    }

    const { ruleId, ...selectedRule } = foundRule;

    const updatedRules = rules.map((rule) => (rule.ruleId === newRule.ruleId ? {
      ...rule,
      ...selectedRule,
      id: newRule.ruleId,
      originalRuleId: ruleId,
    } : rule));

    setRules(updatedRules);
  };

  return (
    <>
      <CardHeader className="d-flex bg-secondary text-white align-items-center">
        {`New ${WORKFLOW_NEW_RULE_LABEL[newRuleType]}`}
      </CardHeader>
      <CardBody className="bg-white">
        <div className="d-flex align-items-center justify-content-center py-4">
          <select onChange={(e) => onSelection(e.target.value)}>
            <option disabled selected value=" ">Select Rule</option>
            {
              availableRules.map((rule) => (
                <option key={rule.ruleId} value={rule.ruleId}>{decode(rule.title)}</option>
              ))
            }
          </select>
        </div>
      </CardBody>
    </>
  );
}

function DefinedWorkflowRule({ rulesAtom, ruleId, originalRule }: ISortableWorkflowRule) {
  const [rules, setRules] = useAtom(rulesAtom);
  const [currentRule, setCurrentRule] = React.useState(
    rules.find((rule) => rule.ruleId === ruleId),
  );
  const [isFieldInEdit, setIsFieldInEdit] = React.useState(false);

  if (!currentRule) {
    return <div />;
  }

  const updateRemovedState = () => {
    const updateRule = {
      ...currentRule,
      isRemoved: !currentRule.isRemoved,
    };
    setCurrentRule(updateRule);

    setRules(rules.map((rule) => (rule.ruleId === ruleId ? updateRule : rule)));
  };

  /**
   *
   * @param value
   */
  const onDescriptionUpdate = (value: string | null | undefined) => {
    const updateRule = {
      ...currentRule,
      ...value && { description: value },
    };
    setCurrentRule(updateRule);

    setRules(rules.map((rule) => (rule.ruleId === ruleId ? updateRule : rule)));
  };

  /**
   *
   * @param value
   * @returns
   */
  const onFrequencyUpdate = (value: RuleFrequency | undefined) => {
    if (value === undefined) {
      return;
    }
    const updateRule = {
      ...currentRule,
      frequency: value,
    };
    setCurrentRule(updateRule);

    setRules(rules.map((rule) => (rule.ruleId === ruleId ? updateRule : rule)));
  };

  /**
   *
   * @param value
   */
  const onThresholdUpdate = (value: number | undefined) => {
    if (value === undefined) {
      return;
    }
    const updateRule = {
      ...currentRule,
      fields: {
        ...currentRule.fields,
        threshold: value,
      },
    };
    setCurrentRule(updateRule);

    setRules(rules.map((rule) => (rule.ruleId === ruleId ? updateRule : rule)));
  };

  /**
   *
   * @param value
   */
  const onCounterUpdate = (value: number | undefined) => {
    if (value === undefined) {
      return;
    }
    const updateRule = {
      ...currentRule,
      fields: {
        ...currentRule.fields,
        target: value,
      },
    };
    setCurrentRule(updateRule);

    setRules(rules.map((rule) => (rule.ruleId === ruleId ? updateRule : rule)));
  };

  /**
   *
   * @param value
   */
  const onLookUpUpdate = (value: number | undefined) => {
    if (value === undefined) {
      return;
    }
    const updateRule = {
      ...currentRule,
      lookup: value,
      fields: {
        ...currentRule.fields,
        target: value,
      },
    };
    setCurrentRule(updateRule);

    setRules(rules.map((rule) => (rule.ruleId === ruleId ? updateRule : rule)));
  };

  /**
   *
   * @param value
   */
  const onTitleUpdate = (value: string | null | undefined) => {
    if (value === undefined) {
      return;
    }
    const updateRule = {
      ...currentRule,
      title: value,
    };
    setCurrentRule(updateRule);

    setRules(rules.map((rule) => (rule.ruleId === ruleId ? updateRule : rule)));
  };

  const ruleState = () => {
    if (isFieldInEdit) {
      return <div className="text-light">Editing</div>;
    }
    if (!isRuleValid(currentRule)) {
      return <div className="text-danger">Invalid</div>;
    }

    if (currentRule.newRuleType) {
      return <div className="text-info">Added</div>;
    }

    if (isRuleModified(currentRule, originalRule)) {
      return <div className="text-info">Modified</div>;
    }

    return <div />;
  };

  return (
    <>
      <CardHeader className="d-flex bg-secondary text-white align-items-center">
        <div className="d-flex w-100">
          <AF1800Badge id={currentRule.originalRuleId || currentRule.ruleId} />
          <TitleField
            value={currentRule?.title}
            originalValue={originalRule?.title}
            updateFieldCallback={onTitleUpdate}
            editingLockCallback={setIsFieldInEdit}
            canEdit={!isAF1800Rule(currentRule.originalRuleId || ruleId)}
            isDisabled={isFieldInEdit}
          />
        </div>
        <div className="d-flex">
          { ruleState()}
        </div>
      </CardHeader>
      <CardBody className="bg-white">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <small className="px-1 text-muted">Rule Type: </small>
            {currentRule.ruleType ? WORKFLOW_RULE_LABEL[currentRule.ruleType] : ' '}
          </div>
          {
            currentRule?.ruleType === 'threshold'
            && (
              <ThresholdTargetField
                value={currentRule?.fields?.threshold}
                originalValue={originalRule?.fields?.threshold}
                max={currentRule?.fields?.absMax}
                updateFieldCallback={onThresholdUpdate}
                canEdit={((currentRule.originalRuleId || ruleId) !== 'SP-FUEL')}
                editingLockCallback={setIsFieldInEdit}
                isDisabled={isFieldInEdit}
              />
            )
          }
          {currentRule?.ruleType === 'lookup' && (
            <NumericInputTargetField
              value={currentRule?.lookup ?? originalRule?.fields?.target}
              originalValue={originalRule?.fields?.target}
              units={currentRule?.fields?.units}
              updateFieldCallback={onLookUpUpdate}
              editingLockCallback={setIsFieldInEdit}
              canEdit
              isDisabled={isFieldInEdit}
            />
          )}
          {isCounterField(currentRule.ruleType, ruleId) && (
            <NumericInputTargetField
              value={currentRule?.fields?.target}
              originalValue={originalRule?.fields?.target}
              units={currentRule?.fields?.units}
              updateFieldCallback={onCounterUpdate}
              editingLockCallback={setIsFieldInEdit}
              canEdit
              isDisabled={isFieldInEdit}
            />
          )}
          <FrequencyField
            value={currentRule.frequency}
            originalValue={originalRule?.frequency}
            updateFieldCallback={onFrequencyUpdate}
            editingLockCallback={setIsFieldInEdit}
            canEdit={!isAF1800Rule(currentRule.originalRuleId || ruleId)}
            isDisabled={isFieldInEdit}
          />
          <Button data-no-dnd="true" className="ml-2" color="link" onClick={() => updateRemovedState()}>
            <MdDelete className="text-danger" />
          </Button>
        </div>
        <div className="d-flex p-1">
          <DescriptionField
            value={currentRule.description}
            originalValue={originalRule?.description}
            updateFieldCallback={onDescriptionUpdate}
            editingLockCallback={setIsFieldInEdit}
            canEdit={!isAF1800Rule(currentRule.originalRuleId || ruleId)}
            isDisabled={isFieldInEdit}
          />
        </div>
      </CardBody>
      {
        currentRule.isRemoved && <RemovedStateOverlay undeleteCallback={updateRemovedState} />
      }
    </>
  );
}

/**
 *
 * @param param0
 * @returns
 */
function SortableWorkflowRule({ rulesAtom, ruleId, originalRule }: ISortableWorkflowRule) {
  const [rules] = useAtom(rulesAtom);
  const [currentRule, setCurrentRule] = React.useState(
    rules.find((rule) => rule.ruleId === ruleId),
  );

  React.useEffect(() => {
    setCurrentRule(rules.find((rule) => rule.ruleId === ruleId));
  }, [rules, ruleId]);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: ruleId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners} // eslint-disable-line react/jsx-props-no-spreading
      {...attributes} // eslint-disable-line react/jsx-props-no-spreading
      className="p-1 mb-2 rounded shadow-small bg-gradient bg-secondary position-relative"
    >
      {
        currentRule
        && WORKFLOW_ADDED_EXISTING_RULES.includes(currentRule?.newRuleType || '')
        && !currentRule?.originalRuleId
        && <NewPredefinedRule newRule={currentRule as INewWorkflowRule} rulesAtom={rulesAtom} />
      }
      {
        (!currentRule?.newRuleType
        || WORKFLOW_CREATED_RULES.includes(currentRule.newRuleType)
        || (
          WORKFLOW_ADDED_EXISTING_RULES.includes(currentRule.newRuleType)
          && currentRule.originalRuleId
        ))
        && <DefinedWorkflowRule ruleId={ruleId} rulesAtom={rulesAtom} originalRule={originalRule} />
      }
    </div>
  );
}

export default SortableWorkflowRule;
